import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Masonry from 'react-masonry-css';
import SEO from '../components/Seo/Seo';
import ContentCard from '../components/ContentCard/ContentCard';
import PostCard from '../components/PostCard/PostCard';
import TopicsBar from '../components/TopicsBar/TopicsBar';
import Layout from '../layout';
import PageOverview from './template.style';

const TagTemplate = ({ pageContext, data }) => {
  const allWordpressPost = data.allWordpressPost.edges;
  const allWordpressWpJournal = data.allWordpressWpJournal.edges;

  const [state] = useState([...allWordpressWpJournal, ...allWordpressPost]);

  const masonryColumns = {
    default: 4,
    1600: 3,
    991: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title={pageContext.name} />
      <TopicsBar />

      <PageOverview className="page-overview">
        <div className="overview-meta">
          <h3>{pageContext.name}</h3>
          <div className="post-count">{state.length} resultaten</div>
        </div>

        <Masonry
          breakpointCols={masonryColumns}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {state.length &&
            state.map(post => {
              const { type } = post.node;

              if (type === 'journal') {
                return (
                  <Fade bottom key={post.node.id}>
                    <ContentCard
                      path={post.node.path}
                      title={post.node.title}
                      image={post.node.featured_media}
                    />
                  </Fade>
                );
              }
              return (
                <Fade bottom key={post.node.id}>
                  <PostCard
                    date={post.node.date}
                    title={post.node.title}
                    excerpt={
                      post.node.childWordPressAcfIntro &&
                      post.node.childWordPressAcfIntro.text
                    }
                    path={post.node.path}
                  />
                </Fade>
              );
            })}
        </Masonry>
      </PageOverview>
    </Layout>
  );
};

export default TagTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    allWordpressPost(
      filter: { tags: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM YYYY", locale: "nl_NL")
          title
          path
          type
          childWordPressAcfIntro {
            text
          }
        }
      }
    }

    allWordpressWpJournal(
      filter: { tags: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          title
          path
          type
          better_featured_image {
            source_url
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 600) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
