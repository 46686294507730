import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

const fade = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const TopicsBarWrapper = styled.div`
  background-color: ${themeGet('color.7')};
  padding: 1em 1em 1em 1.8em;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 58px;
  top: 72px;
  z-index: 900;
  border-top: 1px solid ${themeGet('color.0')};
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background: linear-gradient(
      -90deg,
      ${themeGet('color.7')} 30%,
      rgba(0, 0, 0, 0)
    );
  }

  > div {
    display: inline-block;
  }

  .capital {
    display: none;
    opacity: 0;
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 10px;
  }

  a {
    color: ${themeGet('color.2')};
    font-weight: 400;
    margin-right: 2em;
    line-height: 28px;
    font-family: 'Inter', sans-serif;
    transition: all 0.3s ease;
    &:hover {
      color: ${themeGet('color.3')};
    }
  }

  &.active {
    height: calc(100vh - 72px);
    overflow-y: auto;
    white-space: normal;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0 10% 4rem;
    box-sizing: border-box;

    > div {
      display: block;
    }

    a {
      display: block;
      animation: 400ms fade ease forwards;
      &:hover {
        padding-left: 5px;
      }
    }

    .capwrap {
      flex: 25% 1;
      max-width: 25%;
      min-width: 200px;
    }

    .capital {
      display: block;
      animation: 400ms ${fade} ease forwards;
    }
  }
`;

export const ExpandTopic = styled.div`
  width: 30px;
  height: 30px;
  background: ${themeGet('color.3')};
  color: ${themeGet('color.7')};
  position: fixed;
  right: 25px;
  cursor: pointer;
  border-radius: 180px;
  top: calc(72px + 13px);
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  svg {
    width: 18px;
    height: auto;
  }

  &:hover,
  &.active {
    opacity: 0.9;
    transform: rotate(180deg);
  }
`;

export default TopicsBarWrapper;
