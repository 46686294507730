import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useStaticQuery, graphql, Link } from 'gatsby';
import TopicsBarWrapper, { ExpandTopic } from './TopicsBar.style';

const TopicsBar = () => {
  const [state, setState] = useState({
    expanded: false,
  });

  const expandTopics = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    });
  };

  const topics = useStaticQuery(graphql`
    query {
      allWordpressTag(filter: { count: { ne: 0 } }) {
        edges {
          node {
            id
            name
            path
            count
          }
        }
      }
    }
  `);

  // all topics
  const data = topics.allWordpressTag.edges;
  let lastCapital = '';
  const allTopics =
    data.length &&
    data.map(tag => {
      const capital = tag.node.name.charAt(0).toUpperCase();
      if (lastCapital !== capital) {
        lastCapital = capital;
        return (
          <div key={tag.node.id} className="capwrap">
            <div className="capital">{capital}</div>
            {data
              .filter(
                tagName => tagName.node.name.charAt(0).toUpperCase() === capital
              )
              .map((item, index) => (
                <Link key={`topic_index${index}`} to={item.node.path}>
                  {item.node.name.toLowerCase()}
                </Link>
              ))}
          </div>
        );
      }
      return false;
    });

  return (
    <TopicsBarWrapper
      className={`all_topics ${state.expanded ? 'active' : ''}`}
    >
      {allTopics}
      <ExpandTopic
        className={`expand ${state.expanded ? 'active' : ''}`}
        onClick={expandTopics}
      >
        <IoIosArrowDown />
      </ExpandTopic>
    </TopicsBarWrapper>
  );
};

export default TopicsBar;
